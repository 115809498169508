import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Api } from '../data/api';

const PrivacyPage: React.FC<{api: Api,handleDismiss: () => void}> = ({api,handleDismiss}) => {

  /* eslint import/no-webpack-loader-syntax: off */
  const privacyHtml = require("!!raw-loader!./privacy.html");

  return (
    <IonPage>
    <IonContent fullscreen>
      <IonCard>
          <IonCardHeader className="ion-text-center">
            <IonCardTitle>Privacy Policy</IonCardTitle>
          </IonCardHeader>
            <IonCardContent>
            <div dangerouslySetInnerHTML={{ __html: privacyHtml.default.toString() }} />
          </IonCardContent>
          
          <IonButton expand="block" onClick={() => handleDismiss()} className="ion-margin-top">
                Close
          </IonButton>
        </IonCard>
      </IonContent>
      </IonPage>
  );
};

export default PrivacyPage;
