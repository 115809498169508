import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonList, IonNote, IonPage, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Api } from '../data/api';
import Code from '../model/Code';
import validator from 'validator';
import { QRCode } from 'react-qrcode-logo';
import { arrowForwardOutline } from 'ionicons/icons';

const PrivacyPage: React.FC<{api: Api,handleDismiss?: () => void, theCode: Code}> = ({api,handleDismiss, theCode}) => {

  const [code, setCode] = useState<Code>();
  const [present] = useIonAlert();
  const [emailInput, setEmailInput] = useState<string>(api.fan?.email ? api.fan?.email : '');
  const [emailGiven, setEmailGiven] = useState<boolean>(false); 

  const [isFan, setIsFan] = useState<boolean>(false); 
  const [isBusiness, setIsBusiness] = useState<boolean>(false); 

  const onSubmitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(!validator.isEmail(emailInput)){
      present('Please enter an email', [{ text: 'Ok' }]);
      return;
    }

		if (emailInput !== '' && code?.code){
      const result = await api.sendPromo(emailInput, code.code);
			if (result === true) {
				setEmailGiven(true); 
			} else if(result === false){
				present('Issue sending email, please try again.', [{ text: 'Ok' }]);
			}else{
        present(result, [{ text: 'Ok' }]);
      }
		}

    return false;//To not autosubmit form
	}

  const consumeVoucher = async () => {
    
		if (code?.id){
      const result = await api.updateVoucher(code);
      if(typeof result === "string"){
        present(result, [{ text: 'Ok' }]);
      
      }else if(typeof result === "boolean"){
        //It failed
        present("Issue consuming voucher", [{ text: 'Ok' }]);
      }else{
        //Worked
        setCode(result);
      }

		}

	}

  useEffect(() => {
    if(code?.fan_id && api.fan?.id && code.fan_id === api.fan.id){
      setIsFan(true);
    }

    if(code?.business.fan_id && api.fan?.id && code.business.fan_id === api.fan.id){
      setIsBusiness(true);
    }
  }, [code, api.fan]);

  useEffect(() => {
    if(theCode){
      setCode(theCode);
    }

  }, [theCode]);

 
  return (
    <IonPage>
    <IonContent fullscreen>
      <IonCard>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{code?.business ? `${code.business.name} ${code.deal.name}` : 'Share the Love - Voucher'}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {code?.deal.voucher &&
        <img alt="Voucher" src={`${process.env.REACT_APP_IMAGE_URL}${code.deal.voucher}`} />}
          {code && <IonCardContent>
          {!code.fan_id && !emailGiven &&
          <form onSubmit={(e) => {onSubmitEmail(e)}} autoComplete="off">
          <IonList>
          <IonItem lines='none' className='input ion-padding-horizontal' color="light">
							<IonInput placeholder="Email" type="text" value={emailInput} onIonChange={(e) => setEmailInput(e.detail.value!)}></IonInput>
							<IonButton fill='clear' type="submit" color='primary'>
								<IonIcon icon={arrowForwardOutline}/>
							</IonButton>
							<IonNote color='dark' slot="helper">Send to email</IonNote>
          </IonItem>
          </IonList>
          </form>}
          {code.fan_id &&
          <div style={{textAlign: 'center'}}>
            {code.biz_confirmed ? 'Voucher Used':
           <QRCode value={`${window.location.origin}/voucher/${code.code}`}  />}</div>}

            {!code.fan_id && emailGiven &&<>
              <div style={{textAlign: 'center'}}>Voucher sent to: {emailInput}</div>
              <IonButton fill="outline" className="login-button" expand="block"  onClick={()=> setEmailGiven(false)}>
                Didn't arrive, try again.
              </IonButton></>
            }
            {code.fan_id && isBusiness && !code.biz_confirmed &&
              <IonButton fill="outline" className="login-button" expand="block"  onClick={()=> consumeVoucher()}>
              Consume Voucher
              </IonButton>}
              {!isBusiness && isFan && !code.fan_confirmed &&
              <IonButton fill="outline" className="login-button" expand="block"  onClick={()=> consumeVoucher()}>
              I have used this Voucher
              </IonButton>}
          </IonCardContent>}
          {handleDismiss &&
          <IonButton expand="block" onClick={() => handleDismiss()} className="ion-margin-top">
                Close
          </IonButton>}
        </IonCard>
      </IonContent>
      </IonPage>
  );
};

export default PrivacyPage;
