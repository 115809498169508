import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar, useIonModal } from '@ionic/react';
import AgreePage from '../components/AgreePage';
import PrivacyPage from '../components/PrivacyPage';
import { Api } from '../data/api';


const Tab3: React.FC<{api: Api}> = ({api}) => {

  const [showTerms, dismissTerms] = useIonModal(AgreePage, {
    api: api,
    handleDismiss: () => {
        dismissTerms();
    }
  });

  const [showPrivacy, dismissPrivacy] = useIonModal(PrivacyPage, {
    api: api,
    handleDismiss: () => {
      dismissPrivacy();
    }
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Share the Love</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
              
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
         {api.isAuthed &&
          <>Hi {api.fan?.nickname} {api.fan?.email}</>
        }</div>

      </IonContent>
      <IonFooter>
      <IonToolbar>
      <IonButtons slot="start">
        <IonButton color="primary" onClick={() => showTerms()}>Terms and Conditions</IonButton>
      </IonButtons>
      <IonButtons>
        <IonButton color="primary" onClick={() => api.logOut()}>Logout</IonButton>
      </IonButtons>
      <IonButtons slot="end">
        <IonButton color="primary" onClick={() => showPrivacy()}>Privacy Policy</IonButton>
      </IonButtons>
    </IonToolbar>
    </IonFooter>
    </IonPage>
  );
};

export default Tab3;
