import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonRefresherContent, IonSearchbar, IonTitle, IonToolbar, RefresherEventDetail, useIonModal } from '@ionic/react';
import { RefObject, useRef, useState } from 'react';
import Voucher from '../components/Voucher';
import { Api } from '../data/api';
import Code from '../model/Code';


const VoucherListItem: React.FC<{api: Api; code: Code; ionList: RefObject<HTMLIonListElement>}> = ({api, code, ionList}) => {
  const [present, dismiss] = useIonModal(Voucher, {
    theCode: code,
    handleDismiss: () => dismiss(),
    api: api,
  });
  return (
    <IonItem>
      <IonLabel onClick={()=>present()}>{code.business.name} {code.deal.name}</IonLabel>
    </IonItem>)
}

const Vouchers: React.FC<{api: Api}> = ({api}) => {

  const [searchText, setSearchText] = useState('');
  
  const ionList = useRef<HTMLIonListElement>(null);
  
  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await api.getBusinesses();
    event.detail.complete();
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Share the Love - Vouchers</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        <IonSearchbar 
          animated={true} 
          placeholder="Filter list"
          value={searchText} 
          onIonChange={e => setSearchText(e.detail.value!)}
        ></IonSearchbar>
        <IonList ref={ionList}>
              {api.codes && api.codes.filter((code) => code.business.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1).map((code, index: number) => 
              <VoucherListItem key={index} api={api} code={code} ionList={ionList} />
            )}
          </IonList>
        <IonList>
    </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Vouchers;
