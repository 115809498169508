import {
  IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonFooter, IonHeader, IonInput, IonItem,
  IonLabel, IonList, IonPage, IonRefresher, IonRefresherContent, IonSearchbar, IonSegment, IonSegmentButton, IonTitle, IonToolbar, RefresherEventDetail, useIonModal
} from '@ionic/react';
import { QRCode } from 'react-qrcode-logo';
//https://www.npmjs.com/package/react-qrcode-logo
import { RefObject, useRef, useState } from 'react';

import './BusinessesPage.css';
import Daktech from './Daktech';
import { Api } from '../data/api';
import Business from '../model/Business';
import Deal from '../model/Deal';


const BusinessListItem: React.FC<{api: Api; business: Business; ionList: RefObject<HTMLIonListElement>}> = ({api, business, ionList}) => {

  const [present, dismiss] = useIonModal(ModalBusiness, {
    api: api,
    business: business,
    onDismiss: () => dismiss(),
  });

  //const [present] = useIonAlert();
/*
  const handleDismiss = () => {
    dismissDetails();
  };

  const [presentDetails, dismissDetails] = useIonModal(UserProfile, {
    api,
    user: log.user,
    handleDismiss
  });

  const doShowUser = () => {
    ionList.current && ionList.current.closeSlidingItems();
    presentDetails();
  }
*/
  return (
    <IonItem>
      <IonLabel onClick={()=>present()}>{business.name}</IonLabel>
    </IonItem>)
}

const DealListItem: React.FC<{api: Api; deal: Deal,business: Business}> = ({api, deal,business}) => {

  const [present, dismiss] = useIonModal(ModalDeal, {
    deal: deal,
    business: business,
    onDismiss: () => dismiss(),
    api: api,
  });

  //const [present] = useIonAlert();
/*
  const handleDismiss = () => {
    dismissDetails();
  };

  const [presentDetails, dismissDetails] = useIonModal(UserProfile, {
    api,
    user: log.user,
    handleDismiss
  });

  const doShowUser = () => {
    ionList.current && ionList.current.closeSlidingItems();
    presentDetails();
  }
*/
  return (
    <IonItem>
      <IonLabel onClick={()=>present()}>{deal.name}</IonLabel>
    </IonItem>)
}

const ModalDeal: React.FC<{deal: Deal, business: Business,onDismiss: () => void,api: Api}> = ({deal, business, onDismiss, api}) => {
  

  return (
    <IonPage>
      <IonContent fullscreen>
      <IonCard>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{business.name} {deal.name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCardContent>
        {deal.voucher &&
        <img alt="Voucher" src={`${process.env.REACT_APP_IMAGE_URL}${deal.voucher}`} />}

      <h1 style={{textAlign:'center'}}>{deal.description}</h1>
        <div style={{textAlign:'center'}}>
          <QRCode value={`${window.location.origin}/voucher/${deal.id}-${api.fan?.id}`} />
          </div>
          <h3 style={{textAlign:'center'}}>{deal.percent ? deal.discount + '%' : '$'+deal.discount} discount</h3>
          
          </IonCardContent>
          </IonCard>
      </IonContent>
      <IonFooter>
        <IonToolbar>
        <IonButtons>
            <IonButton color="medium" onClick={() => onDismiss()}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

const ModalBusiness: React.FC<{api:Api,business: Business, onDismiss: () => void}> = ({api,business,onDismiss}) => {
  const [tabSelected, setTab] = useState<string>('profile');

  return (
    <IonPage>
       <IonHeader>
      <IonToolbar>
        <IonSegment onIonChange={(e) => e.detail.value && setTab(e.detail.value)} value={tabSelected}>
          <IonSegmentButton value="profile">
            Business
          </IonSegmentButton>
          {business.maps && 
          <IonSegmentButton value="map">
            Map
          </IonSegmentButton>}
          {api.fan?.superfan &&
          <IonSegmentButton value="qr">
            Deals
          </IonSegmentButton>}
        </IonSegment>
      </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      {tabSelected === 'profile' && business.profile && <div dangerouslySetInnerHTML={{ __html: business.profile }} />}
      {tabSelected === 'map' && business.maps && 
            <div style={{
            padding: 1,
            height:"100%",
            maxWidth: 700}}>
          <iframe 
            className="gn-iframe" 
            src={business.maps}
            height="100%" 
            frameBorder='0'
            style={{ width: "100%" }} 
            ></iframe>
          </div>}
        {tabSelected === 'qr' && <IonList>
          {business.deals && business.deals.map((deal, index: number) => 
              <DealListItem key={index} api={api} deal={deal} business={business} />
            )}
        </IonList>
          }


      </IonContent>
      <IonFooter>
        <IonToolbar>
        <IonButtons>
            <IonButton color="medium" onClick={() => onDismiss()}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};


const BusinessesPage: React.FC<{api: Api}> = ({api}) => {
  const [searchText, setSearchText] = useState('');

  
  const ionList = useRef<HTMLIonListElement>(null);
  
  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await api.getBusinesses();
    event.detail.complete();
  }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Share the Love</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Share the Love</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        <IonSearchbar 
          animated={true} 
          placeholder="Filter list"
          value={searchText} 
          onIonChange={e => setSearchText(e.detail.value!)}
        ></IonSearchbar>
        <IonList ref={ionList}>
              {api.businesses && api.businesses.filter((business) => business.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1).map((business, index: number) => 
              <BusinessListItem key={index} api={api} business={business} ionList={ionList} />
            )}
          </IonList>
        <IonList>
    </IonList>
      </IonContent>
    </IonPage>
  );
};

export default BusinessesPage;
