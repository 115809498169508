import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  useIonAlert,
  useIonLoading
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { albums,  businessOutline, personCircleOutline } from 'ionicons/icons';

import LoginPage from './pages/LoginPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useEffect } from 'react';
import useServerConnection from './data/api';
import BusinessesPage from './pages/BusinessesPage';
import ProfilePage from './pages/ProfilePage';
import VoucherPage from './pages/VoucherPage';
import VouchersPage from './pages/VouchersPage';

setupIonicReact();

const App: React.FC = () => {
  const api = useServerConnection();
  const [isLoading, loadingComplete] = useIonLoading();

  const [presentUpdate] = useIonAlert();

  useEffect(() => {
    window.addEventListener('hasUpdate', e => 
    presentUpdate({
      header: 'Update available',
      message: 'Please select update to get the latest version',
      buttons: ['Cancel', { text: 'Update', handler: (d) => window.location.reload()}],
      onDidDismiss: (e) => console.log('dismissed update'),
    })
    );
  },[]);

  useEffect(() => {
    if(api.loading){
      isLoading();
    }else{
      loadingComplete();
    }

  },[api.loading]);
  
  return (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/directory" render={props => { 
            return api.isAuthed ? <BusinessesPage {...props} api={api} /> : <LoginPage {...props} api={api} />;} } />
          
          <Route exact path="/vouchers">
            <VouchersPage api={api} />
          </Route>
          <Route path="/profile"  render={props => { 
            return api.isAuthed ? <ProfilePage {...props} api={api} /> : <LoginPage {...props} api={api} />;} } />
            
          <Route path="/voucher/:id" render={props => { return <VoucherPage {...props} api={api} />;} }/>

          <Route exact path="/">
            <Redirect to="/vouchers" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="directory" href="/directory">
            <IonIcon icon={businessOutline} />
            <IonLabel>List</IonLabel>
          </IonTabButton>
          <IonTabButton tab="vouchers" href="/vouchers">
            <IonIcon icon={albums} />
            <IonLabel>Vouchers</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/profile">
            <IonIcon icon={personCircleOutline} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
)};

export default App;
function presentUpdate(arg0: { header: string; message: string; buttons: (string | { text: string; handler: (d: any) => void; })[]; onDidDismiss: (e: any) => void; }): void {
  throw new Error('Function not implemented.');
}

