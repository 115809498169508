import { IonContent, IonPage, useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Api } from '../data/api';
import Code from '../model/Code';

import Voucher from '../components/Voucher';


interface PageProps
  extends RouteComponentProps<{
    id: string;
  }> {api: Api}

const VoucherPage: React.FC<PageProps> = ({ api,match }) => {
  const [present] = useIonAlert();
  const [code, setCode] = useState<Code>();
 

  useEffect(() => {
    //Need to be initalised so we know if we have auth
    if(api.initalised){
      console.log('useEffect Page Load',match.params.id);
      if(match.params.id && !code){
        console.log('Code not set getting...');
        (async function inlineAsync() {
          const result = await api.getPromo(match.params.id);
          console.log('result',result);
          //There is a message from server
          if(typeof result === "string"){
            present(result, [{ text: 'Ok' }]);
          
          }else if(typeof result === "boolean"){
            //It failed
            present("Error loading code", [{ text: 'Ok' }]);
          }else{
            //Worked
            setCode(result);
          }
      })();
      }
    }

  }, [match.params.id, api.initalised]);


  return (code ? <Voucher api={api} theCode={code} />:
    <IonPage>
      <IonContent fullscreen>
      </IonContent>
    </IonPage>
  );
};

export default VoucherPage;
