import { Storage } from '@ionic/storage';
import { useEffect, useState } from "react";
import Business from '../model/Business';
import Fan from '../model/Fan';
import Code from '../model/Code';
import { Lookups, MyNotification } from '../model/interfaces';


const API = process.env.REACT_APP_SERVER_URL;
const STORE = 'STL_';

export interface Token{
    id: number;
    token: string;
    pass: string;
    expires?: number;
}


export interface Api{
    isAuthed: boolean;
    initalised: boolean;
    logOut: () => Promise<false | undefined>,
    online: boolean;
    loading: boolean;
    agree: boolean;
    agreeDate: number;
    clearAgree: () => void;
    updateNotification: MyNotification;
    fan: Fan | undefined;
    lookups: Lookups | undefined;
    codes: Code[];
    sendCode: (code:string) => Promise<boolean>;
    sendEmail: (email:string) => Promise<boolean | string>;
    getPromo: (code:string) => Promise<boolean | string | Code>;
    updateVoucher: (code:Code) => Promise<boolean | string | Code>;
    sendPromo: (email:string, code:string) => Promise<boolean | string>;
    getBusinesses: () => void;
    getCodes: () => void;
    businesses: Business[];
    emailToken: Token | undefined;
    cancelEmail: () => void;
}

export const useServerConnection = () => {
    //Required for auth
    const [token, setToken] = useState<Token>();
    const [emailToken, setEmailToken] = useState<Token>();
    const [isAuthed, setIsAuthed] = useState<boolean>(false);
    const [lookups, setLookups] = useState<Lookups>();
    const [updateNotification, setUpdateNotification] = useState<MyNotification>({
      cleared: true, message: '',last_cleared: 0
    });
    const [initalised, setInitalised] = useState<boolean>(false);

    const [online, setOnline] = useState<boolean>(true);
    //Used to show loading indicator - stops double submit
    const [loading, setLoading] = useState<boolean>(false);
    //Agree to TCs
    const [agree, setAgree] = useState(false);
    const [agreeDate, setAgreeDate] = useState<number>(0);

    //Local data
    const [fan, _setFan] = useState<Fan>();
    const setFan = async (tmp: Fan)=>{
      await store.set(`${STORE}fan`,tmp);
      _setFan(tmp);
    }

    const [businesses, _setBusinesses] = useState<Business[]>([]);
    const setBusinesses = async (tmp: Business[])=>{
      await store.set(`${STORE}businesses`,tmp);
      _setBusinesses(tmp);
    }

    const [codes, _setCodes] = useState<Code[]>([]);
    const setCodes = async (tmp: Code[])=>{
      await store.set(`${STORE}codes`,tmp);
      _setCodes(tmp);
    }

    const logOut = async () => {
      if (!token) return false;

      setLoading(true);
      try {
        store.clear();
        setLoading(false); 
        window.location.reload(); 
      } catch (err) {
        //console.log('errrrr'); 

      }
    }

    //Setup offline storage
    const store = new Storage();
    store.create();

    useEffect(() => {
      window.addEventListener("offline", () => {
        setOnline(false);
      });
      window.addEventListener("online", () => {
        setOnline(true);
      });
  
      return () => {
        window.removeEventListener("offline", () => {
          setOnline(false);
        });
        window.removeEventListener("online", () => {
          setOnline(true);
        });
      };
    }, []);

    //On first load
    useEffect(() => {
        console.log('API useEffect');
        (async function getStorage() {
          //Load all data from local storage
          console.log('Load from local storage');

          const tmpToken:Token = await store.get(`${STORE}token`);
          setToken(tmpToken);

          const tmpUpdates = await store.get(`${STORE}updateNotification`);
          setUpdateNotification(tmpUpdates ? tmpUpdates : false); 

          const tmpEmailToken:Token = await store.get(`${STORE}email_token`);
          setEmailToken(tmpEmailToken);

          const tmpLookups = await store.get(`${STORE}lookups`);
          setLookups(tmpLookups ? tmpLookups : undefined); 
          
          const tmpAgree = await store.get(`${STORE}agree`);
          setAgree(tmpAgree ? tmpAgree : false);
          
          const tmpAgreeDate = await store.get(`${STORE}agreeDate`);
          setAgreeDate(tmpAgreeDate);
          
          const tmpFan = await store.get(`${STORE}fan`);
          _setFan(tmpFan);

          const tmpBusinesses = await store.get(`${STORE}businesses`);
          _setBusinesses(tmpBusinesses);

          const tmpCodes = await store.get(`${STORE}codes`);
          _setCodes(tmpCodes ? tmpCodes : []);
         
    
        })();
      }, []);

      

      //When token is set/changed, check if authed
    useEffect(() => {
      if(!online) return;
     
      if(token && !isAuthed){
          //Try authencate with token, 
          (async function inlineAsync() {
              const result = await checkAuthed(token);
              if(result){
                  //console.log('Authed'); 
                  setIsAuthed(true);
              }else{
                  //console.log('Not authed'); 
              }
              //We are good to go
              setInitalised(true);
          })();
      }else if(!token){
        //else if first time
        //Get the lookups (terms...)
        (async function inlineAsync() {

          await checkAuthed();
          setInitalised(true);

        })();

      }//Else we are already authed and should have lookups loaded

      if(isAuthed){
        (async function inlineAsync() {
          getBusinesses();
          getCodes();
      })();
      }

  }, [token, isAuthed, online]);

      //Check if there is a new terms to agree to
    useEffect(() => {
      //check if agreed date is less than the latest terms, or never agreed
      //Only check if they have already agreed otherwise they have to do it anyway
      if(agree && lookups?.terms?.modified 
          && (!agreeDate || new Date(lookups.terms.modified).getTime() > agreeDate )){
            //reset agreed
          store.set(`${STORE}agree`,false);
          setAgree(false);
      }

      //check for new update

      if(updateNotification.cleared && lookups?.updates?.modified && 
        new Date(lookups.updates.modified).getTime() > updateNotification.last_cleared
        ){
          const newUpdate: MyNotification = {...updateNotification, cleared: false };
          store.set(`${STORE}updateNotification`,newUpdate);
          setUpdateNotification(newUpdate);
      }
      

    }, [lookups]); 

      function sleep(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      const clearAgree = () => {
        console.log('clearAgree');
        store.set(`${STORE}agree`,true);
        setAgree(true);
      };

      
      const checkAuthed = async (tmpToken?: Token) => {
        console.log('checkAuthed: ',tmpToken);
        setLoading(true);
        try {
          const response = await fetch(`${API}/auth.json`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': tmpToken ? JSON.stringify({bob: 'Hi', id: tmpToken.id, token: tmpToken.token, pass: tmpToken.pass}) : ''
            }
          });
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
          var data = await response.json();
          //Get lookup data
          if(response.status === 200 && data?.lookups){
            setLookups(data.lookups);
            store.set(`${STORE}lookups`, data.lookups);

            //we are not authing so return true / success
            if(!tmpToken){
              setLoading(false);
              return true;
            }
          }

          //console.log('data from auth', data); 
          if(response.status === 200 && data?.fan?.id){
            console.log("checkAuthed",'Success');
            setFan(data.fan);
            
            setLoading(false);
            return true;
          }
          setLoading(false);
          return false; 

        } catch (error) {
          //console.log(error);
        }
      
        //console.log("checkAuthed",'Failed');
        setLoading(false);
        return false;
      };

      const getCodes = async () => {
        
        console.log("getCodes");
        
        try {
          const response = await fetch(`${API}/codes.json`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          var data = await response.json();
          if(data?.status == 200 && data.codes){
            const codes = data.codes; 
            //This will overwrite any local ones
            setCodes(codes); 
  
            return true; 
          }
          return false; 
        } catch (error) {
          //console.log(error);
        }
      };


      const getBusinesses = async () => {
        
        console.log("getBusinesses");
        if(!token) return false;
        
        try {
          const response = await fetch(`${API}/businesses.json`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass})
            }
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          var data = await response.json();
          if(data?.status == 200 && data.businesses){
            const businesses = data.businesses; 
            //console.log('setting tournments in getTournaments');
            setBusinesses(businesses); 
  
            return true; 
          }
          return false; 
        } catch (error) {
          //console.log(error);
        }
        return false; 
      };

    const updateVoucher = async (code: Code) => {
      console.log('updateVoucher: ', code);
            
      setLoading(true);
      
      try {
        const response = await fetch(`${API}/codes/consume/${code.id}.json?code=${code.code}`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass}) : ''
          },
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        var data = await response.json();
        if(data?.status == 200 && data.code){
          
          const newCode: Code = data.code;
          //Add to local codes
          //Check if exists
          if(codes.some(e => e.id === newCode.id)){
            //update
            setCodes(codes.map((code) => (code.id = newCode.id) ? newCode: code));
          }else{
            //Add
            setCodes([...codes, newCode]);
          }
          
          setLoading(false);
          return newCode;
        }

        //error but has message
        if(data?.message){
          setLoading(false);
          return data.message;
        }
      } catch (error) {
        console.log(error);
        
      }

      setLoading(false);
      return false;
    }

    const getPromo = async (code: string) => {
      console.log('getPromo: ', code);
      if(code.length == 0 ) return false;
      
      setLoading(true);
      
      try {
        const response = await fetch(`${API}/codes/get/${code}.json`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass}) : ''
          },
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        var data = await response.json();
        if(data?.status == 200 && data.code){
          console.log('Got code',data.code);
          const newCode: Code = data.code;
          //Add to local codes
          //Check if exists
          if(codes.some(e => e.id === newCode.id)){
            //update
            setCodes(codes.map((code) => (code.id = newCode.id) ? newCode: code));
          }else{
            //Add
            setCodes([...codes, newCode]);
          }
          
          setLoading(false);
          return newCode;
        }

        //error but has message
        if(data?.message){
          setLoading(false);
          return data.message;
        }
      } catch (error) {
        console.log(error);
        
      }

      setLoading(false);
      return false;
    }

    const sendPromo = async (email: string, code: string) => {
      //console.log('sendPromo: ', email);
      if(email.length == 0 || code.length == 0 ) return false;
      
      setLoading(true);
      
      try {
        const response = await fetch(`${API}/codes/send.json`,{
          method: 'POST',
          body: JSON.stringify({email: email, code: code}),
          headers: {
            'Content-Type': 'application/json'
          },
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        var data = await response.json();
        if(data?.status == 200){
          
          setLoading(false);
          return true;
        }

        //error but has message
        if(data?.message){
          setLoading(false);
          return data.message;
        }
      } catch (error) {
        //console.log(error);
        
      }
  
      setLoading(false);
      return false;
  };

    
    //Send code to authicate
    //Will have temp token from the sendEmail
    const sendCode = async (code:string) => {
      //console.log('sendCode: ',code);
      if(!token) return false;

      setLoading(true);
      try {
        const response = await fetch(`${API}/auth/login.json`,{
          method: 'POST',
          body: JSON.stringify(
            {
              code: code,
              token: token.token,
              id: token.id
            }
            ),
          headers: {
            'Content-Type': 'application/json'
          },
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
  
        var data = await response.json();
        //console.log(data);
        if(data?.status == 200 && data.token && data.fan){
          const newToken :Token = data.token;

          store.set(`${STORE}token`,newToken);
          setToken(newToken);

          setFan(data.fan);

          setIsAuthed(true);

          setLoading(false);
          return true;
        }
  
      } catch (error) {
        //console.log(error);
      }
  
      setLoading(false);
      return false;
  };


  const sendEmail = async (email: string) => {
      //console.log('sendEmail: ', email);
      if(email.length == 0) return false;
      
      setLoading(true);
      
      try {
        const response = await fetch(`${API}/auth/login.json`,{
          method: 'POST',
          body: JSON.stringify({email: email}),
          headers: {
            'Content-Type': 'application/json'
          },
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        var data = await response.json();
        if(data?.status == 200 && data.token?.id){
          //console.log('sendEmail: ', data);
          const newToken :Token = {id: data.token.id, token: data.token.token, pass: ''};//No Pass for temp token, this is sent in email
          setToken(newToken);
          await store.set(`${STORE}token`,newToken);
          
          setLoading(false);
          return true;
        }

        //error but has message
        if(data?.message){
          setLoading(false);
          return data.message;
        }
      } catch (error) {
        //console.log(error);
        
      }
  
      setLoading(false);
      return false;
  };

  const cancelEmail = () => {
    setToken(undefined);
    store.set(`${STORE}token`,undefined);
  };

    const api: Api = {
        isAuthed,
        initalised,
        online,
        logOut,
        loading,
        agree,
        agreeDate,
        updateNotification,
        clearAgree,
        getBusinesses,
        businesses,
        getCodes,
        fan,
        lookups,
        getPromo,
        updateVoucher,
        sendPromo,
        codes,
        sendCode,
        sendEmail,
        emailToken,
        cancelEmail
    };

    return api; 

};

export default useServerConnection;