import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonNote, IonPage, IonRow, IonTitle, IonToolbar, useIonAlert, useIonModal } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import AgreePage from '../components/AgreePage';
import PrivacyPage from '../components/PrivacyPage';
import { Api } from '../data/api';
import '../theme/variables.css';

import validator from 'validator';

/*
Status Codes - https://restfulapi.net/http-status-codes/
1xx: Informational – Communicates transfer protocol-level information.
2xx: Success – Indicates that the client’s request was accepted successfully.
3xx: Redirection – Indicates that the client must take some additional action in order to complete their request.
4xx: Client Error – This category of error status codes points the finger at clients.
5xx: Server Error – The server takes responsibility for these error status codes.

Looking for 200 OK

401 Unauthorized - Not signed in
403 Forbidden - Is singed in but no access to what is requested
404 Not Found
408 Request Timeout

*/

interface LoginPageProps  {
	api: Api,
}


const LoginPage: React.FC<LoginPageProps> = ({ api}) => {
  const [present] = useIonAlert();

  const [emailGiven, setEmailGiven] = useState<boolean>(false); 

	const [emailInput, setEmailInput] = useState<string>('');
  const [codeInput, setCodeInput] = useState<string>(''); 
  
	const onSubmitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(!validator.isEmail(emailInput)){
      present('Please enter an email', [{ text: 'Ok' }]);
      return;
    }

		if (emailInput !== ''){
      const result = await api.sendEmail(emailInput);
			if (result === true) {
				setEmailGiven(true); 
			} else if(result === false){
				present('Issue sending email, please try again.', [{ text: 'Ok' }]);
			}else{
        present(result, [{ text: 'Ok' }]);
      }
		}

    return false;//To not autosubmit form
	}

  const onSubmitCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(codeInput.length < 6){
      present('Please enter full code', [{ text: 'Ok' }]);
      return;
    }

    if (await api.sendCode(codeInput)){
      //history.push('/tournaments'); 
    } else {
      present('Code not valid, please try again.', [{ text: 'Ok' }]);
      //console.log('nay not authed'); 
    }
    return false;//To not autosubmit form
  }

  const [showTerms, dismissTerms] = useIonModal(AgreePage, {
    api: api,
    handleDismiss: () => {
        dismissTerms();
    }
  });

  const [showPrivacy, dismissPrivacy] = useIonModal(PrivacyPage, {
    api: api,
    handleDismiss: () => {
      dismissPrivacy();
    }
  });

  return (
    <IonPage>
      <IonContent fullscreen >
          <IonGrid style={{height: '100%'}}>
              <IonRow style={{height: '50%'}}>
                  <IonCol className="ion-text-center" style={{width: '100%'}}>
                      <h4>Enter email to get started</h4>
                  </IonCol>
              </IonRow>
              <form onSubmit={(e) => {!emailGiven ? onSubmitEmail(e) : onSubmitCode(e)}} autoComplete="off">
              <IonRow style={{height: '20%'}}>
					<IonCol size="1"></IonCol>
          <IonCol size="10" className="ion-text-center">
            {!emailGiven ? 
              <IonRow className="ion-justify-content-center">
                <IonCol size='11' style={{backgroundColor:'#FFF'}}>
                  <IonItem lines='none' className='input ion-padding-horizontal' color="light">
                    <IonInput value={emailInput} onIonChange={(e) => setEmailInput(e.detail.value!)} placeholder="Email" type="text"></IonInput>
                    <IonNote color='dark' slot="helper">Enter Email Here</IonNote>
                  </IonItem>
                </IonCol>
              </IonRow>
            :
              <IonRow className="ion-justify-content-center ion-margin-top">

                <IonCol size='11' style={{backgroundColor:'#FFF'}}>
                  <IonItem lines='none' className='input ion-padding-horizontal' color="light">    
                  <IonInput 
                    value={codeInput} 
                    onIonChange={e => e.detail?.value ? setCodeInput( e.detail.value.toUpperCase().replace(/[^a-z0-9]/gi, '').substring(0, 6) ) : setCodeInput('')} 
                    placeholder="CODE" 
                    maxlength={10} 
                    type="text"></IonInput>
                  <IonNote color='dark' slot="helper">Enter Code Here</IonNote>
                </IonItem>
                </IonCol>
              </IonRow>
            }
          </IonCol>
					<IonCol size="1"></IonCol>
        </IonRow>
        <IonRow style={{height: '15%'}}>
				  <IonCol size="1"></IonCol>
            <IonCol style={{backgroundColor:'#FFF'}}>
              <IonButton expand="block" 
                color='primary' 
                fill='outline' 
                type="submit"
                className="ion-margin">{!emailGiven ? 'Submit Email' : 'Submit Code' } <IonIcon className='ion-margin-horizontal' icon={arrowForwardOutline}/></IonButton>
              </IonCol>
          <IonCol size="1"></IonCol>
        </IonRow>

        </form>
            {emailGiven &&
        <IonRow style={{height: '15%'}}>
				  <IonCol size="1"></IonCol>
            <IonCol style={{backgroundColor:'#FFF'}}>
              <IonButton fill="outline" className="login-button" expand="block"  onClick={()=> setEmailGiven(false)}>
                Code didn't arrive, try again.
              </IonButton>
            </IonCol>
          <IonCol size="1"></IonCol>
        </IonRow>
            }
        </IonGrid>
      </IonContent>
      <IonFooter>
      <IonToolbar>
      <IonButtons slot="start">
        <IonButton color="primary" onClick={() => showTerms()}>Terms and Conditions</IonButton>
      </IonButtons>
      <IonButtons slot="end">
        <IonButton color="primary" onClick={() => showPrivacy()}>Privacy Policy</IonButton>
      </IonButtons>
    </IonToolbar>
    </IonFooter>
    </IonPage>
  );
};

export default LoginPage;
