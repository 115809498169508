import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Api } from '../data/api';


const AgreePage: React.FC<{api: Api,handleDismiss: () => void}> = ({api,handleDismiss}) => {


  return (
    <IonPage>
    <IonContent fullscreen>
      <IonCard>
          <IonCardHeader className="ion-text-center">
            <IonCardTitle>Terms and Conditions</IonCardTitle>
            <IonCardContent>
              {api.lookups?.terms && new Date(api.lookups.terms.modified).getTime() > api.agreeDate && <h1>Terms and Conditions have updated</h1>}
             
              {api.lookups?.terms?.content && <div dangerouslySetInnerHTML={{ __html: api.lookups?.terms.content }} />}
            
          </IonCardContent>
          </IonCardHeader>
          {api.agree ? 
          <IonButton expand="block" onClick={() => handleDismiss()} className="ion-margin-top">
                Close
          </IonButton>:
          <IonButton expand="block" onClick={() => api.clearAgree()} className="ion-margin-top">
              I Agree
        </IonButton>
          }
          

        </IonCard>
      </IonContent>
      </IonPage>
  );
};

export default AgreePage;
